<template>
  <div>
    <br>
    <h5> Line of Symmetry</h5>
    <p class="big mt-2">
      A shape or an object is said to have symmetry if it could be folded in half such that one half is the mirror image of the other half. In other words, if we place a mirror along the fold line, the image of the left half in the mirror will be identical to the right half. <br>
      The fold line, which is the plane of mirror, is known as line of symmetry. A symmetrical shape can have more than one line of symmetry.
    </p>
    <h5>Problem Description</h5>
    <p class="big mt-2">
      You're given shapes with one or multiple lines of symmetry.
      <br>
      The goal of this exercise is to identify shapes that have specified number of lines of symmetry. Please read the instructions given on the MagicGraph for more details.
    </p>
    <div style="text-align:left">
      <h5> MagicGraph | Symmetric Shapes </h5>
    </div>
    <p class="big mt-2">
      Use the MagicGraph below to participate in the exercise and provide your response. Tap on <i class="shuffler ma-1" /> button to move over to the next question. Tap on <i class="tester ma-1" /> to test your answer.
      Tap on <i class="undoer ma-1" /> button to start over from the begining.
      <br>
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
    <br>
    <h5> Navigation Icons </h5>
    <p class="big mt-2">
      This MagicGraph contains a number of navigation icons/buttons to help you navigate through different parts of the exercise. See below for an explanation of different navigation icons.
    </p>
    <p>
      <i class="redoer ma-1" /> is the 'Next' button. Tap on this button to move over to the next question. <br>
      <i class="tester ma-1" /> is the 'Check Your Answer' button. Tap on this button to check your answer.<br>
      <i class="logor ma-1" /> is the 'Get a Hint' button. Tap on this button to receive a hint on how to solve this problem. <br>
      <i class="undoer ma-1" /> is the 'Start Over' button. Tap on this button to start over the exercise from the begining.
    </p>
    <h5> Marks & Indications </h5>
    <p class="big mt-2">
      The marks and indications are located on the top-left corner of the MagicGraph, and show your attempt status. <br>
      The right checkmark <i class="righter ma-1" />  indicates that your provided response was correct. <br>
      The crossmark <i class="wrong ma-1" />  indicates that your provided response was incorrect. <br>
      The exclaimation mark<i class="missed ma-1" /> indicates an unattempted (missed) response. <br>
    </p>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'QuizSymmetry',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Symmetric Shapes';
    this.$store.commit('navigation/changeTitle', title);
  /* this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Probability', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Probability of an Event', img:'/assets/number-2.svg', action: () => this.goto('types')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
*/
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Shapes with Symmetry',
          titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively about shapes with symmetry.'}
                ]
        }
   },
}
</script>
