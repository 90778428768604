import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placegraph,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeUndo,
  placeRedo,
  placeStartOver,
  drawDash,
  placeTapeVert,
  placeTapeHori,
  placeMiddleText,
  placePlay,
  placeTest,
  placeBoldText,
  setColor,
  drawSq,
  drawRec,
  drawRegPoly
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS////////////////////////////////
var graph =createSpace(-6,6,-5,7);
makeResponsive(graph);
graph.suspendUpdate();
graph.options.layer['image'] =13;
graph.options.layer['circle'] =13;
graph.options.layer['chart'] =13;
graph.options.layer['sector'] =13;
graph.options.layer['line'] =13;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
//var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[0].setAttribute({name:'X'});
//ax[1].setAttribute({name:'Y'});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/*************************ANSWERS*********************/
var index_selected_answer = -1;
const XList = [2, 3, 1, 3, 1];
const YList = [4, 4, 3, 2, 3];
var lenArr = ['at least 1', 'at least 2', 'exactly 3', 'exactly 2', 'at least 3'];
var fracArr = [3, 3, 3, 2, 2];
var Ptm = drawPoint(graph, XList[0], YList[0]);
Ptm.setAttribute({visible:false});
const answers = [['40', '10', '20', '30'], ['9', '18', '6', '21'],['6', '10', '20', '30'],['16', '8', '6', '12'], ['24', '20', '18', '16']];
const index_right_answer = [16, 15, 5, 7, 8];
/**/
/**********************************************************************************/
var i=0; var k=0; var vis=false; var status =[0,0,0,0];
const shapes=[1,3,5,7]; var shapeIndex=[0,0,0,0];
//graph.create('image', ['/assets/pizza.svg', [positionX-2.5, positionY-3.5],[5,5]],{fixed:true});
//var charts =graph.create('circle', [[positionX,positionY-2], [positionX+2.5, positionY-2]], {visible:true,fillOpacity:0.25, fixed:true, dash:1, strokeColor: 'black', strokeWidth:0, fillColor:'white', layer: 2, highlightStrokeColor: 'black'})
var sum = function()
{ let total=0;
  for(let i=0; i<4; i++)
  {
    total = total + shapeIndex[i];
  }
  return total.toFixed(0);
}
//placeMiddleText(graph, positionX, 4.5, '(Click on a slice to pick it.)')
//placeBoldText(brd1, 3, 8, function(){ return 'Taken : Leftover = '+ fracArr[k]+' : '+lenArr[k]});
//placeMiddleText(graph, positionX, 4.5, function(){return 'Picked to Leftover ='+sum()+ ' : '+(lenArr[k]-sum())});
//placeMiddleText(graph, positionX, 3.5, function(){return 'Picked to Overall ='+sum()+ ' : '+lenArr[k]});
//////////////////////////////////////////////////////////////
var Pt1=drawPoint(graph, -4, 1);
setSize(graph, Pt1, 0);
var Pt2=drawPoint(graph, -4, 4);
setSize(graph, Pt2, 0);
var Pt3=drawPoint(graph, -1, 1);
setSize(graph, Pt3, 0);
var tri=[];
tri[0] =drawTriangle(graph, Pt1, Pt2, Pt3);
//tri1.on('down', function(){tri1.setAttribute({fillColor:'red'})});
///////////////////////////////////////////
var Pt4=drawPoint(graph, 1, 1);
setSize(graph, Pt4, 0);
var Pt5=drawPoint(graph, 3.5, 4);
setSize(graph, Pt5, 0);
var Pt6=drawPoint(graph, 4, 1);
setSize(graph, Pt6, 0);
tri[1]=drawRegPoly(graph, Pt4, Pt6, 4);
///////////////////////////////////////////////////////
var Pt7=drawPoint(graph, 1, -3);
setSize(graph, Pt7, 0);
var Pt8=drawPoint(graph, 3, -1);
setSize(graph, Pt8, 0);
var Pt9=drawPoint(graph, 4, -3);
setSize(graph, Pt9, 0);
tri[2]=drawRegPoly(graph, Pt7, Pt9, 3);
////////////////////////////////////////////////////////////////////
var Pt10=drawPoint(graph, -4, -3);
setSize(graph, Pt10, 0);
var Pt11=drawPoint(graph, -3, 0);
setSize(graph, Pt11, 0);
var Pt12=drawPoint(graph, -1, -1);
setSize(graph, Pt12, 0);
tri[3]=drawRec(graph, Pt10, Pt12);
var ini=function(){
  tri.forEach(function(item){item.setAttribute({fillColor:'white', borders:{visible:true, strokeWidth:2, strokeColor:'black'}})});
  for(let i=0; i<5; i++){
    status[i]=0;
    shapeIndex[i]=0;
  }
}
ini();
///////////////////////////////////////
graph.on('down', function(evt)
{
  let coords = graph.getMousePosition(evt);
  for(let i=0; i<4; i++)
  {
    if(tri[i].hasPoint(coords[0], coords[1])==true)
    {
      if(Math.abs(Math.sin(status[i]*Math.PI/2))<1.0)
      {
      tri[i].setAttribute({fillColor:'red'});
      shapeIndex[i] = shapes[i];
      }
      else {
      tri[i].setAttribute({fillColor:'white'});
      shapeIndex[i] = 0;
      }
      status[i] +=1;
    }
  }
}
);
/***********************************************************/
/********************************Place Question ****************************/
var question = placeQuestion(graph, function(){return 'Pick shapes that have  ' + lenArr[k] +' line of symmetry.'});
var comment = placeComment(graph, '');
var note = writeHTMLText(graph, positionX, positionY+height/2.-0.5, 'You did not make a selection.');
note.setAttribute({visible:false, color:'red'});
//print(graph, ()=>(alpha.Value()*180/Math.PI).toFixed(1));
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'left');
hoverMe(graph, test, 'Check Your Answer', -20, 0);
/**/
var next = placePlay(graph);
hoverMe(graph, next, 'Next Question', 0, 0);
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, 0);
/***************************************/
///////////////////////////////////////////////////////////////
/*
var ansList = ['40', '10', '20', '30'];
var ansArray = placeAnswers(graph, ansList);
for(let ij=0; ij<=3; ij++)
{
  ansArray[ij].on('down', function(){
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray[ij].setAttribute({color:'black'});
  pointers[ij].setAttribute({visible:true});
  index_selected_answer = ij.valueOf();})
}
*/
////////////////////////////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY, 'Sign up for free & get help on this problem (and many more).');
hint.setAttribute({visible:false, color:'white'});
/**************************graph BUTTON ***********************/
test.on('down', function()
{
  note.setAttribute({visible:false});
  index_selected_answer=sum()
  if(k<=XList.length-1)
  {
    if(index_selected_answer == index_right_answer[k] && yourTotal<XList.length)
	  { //is correct
        cross[k].setAttribute({visible:false});
        exclaim[k].setAttribute({visible:false});
        check[k].setAttribute({visible:true});
      }
      else if(index_selected_answer != index_right_answer[k] && yourTotal<XList.length && index_selected_answer != 0)
      { //is incorrect
        exclaim[k].setAttribute({visible:false});
        check[k].setAttribute({visible:false});
        cross[k].setAttribute({visible:true});
      }
	  else if (index_selected_answer == 0 && yourTotal<XList.length)
	  {   check[k].setAttribute({visible:false});
        cross[k].setAttribute({visible:false});
        exclaim[k].setAttribute({visible:true});
        note.setAttribute({visible:true});
	  }
  }
});
/*****************************SHUFFLE BUTTON ***********************/
next.on('down', function(){
  note.setAttribute({visible:false});
  console.log(status, sum);
  index_selected_answer=1*sum()
  if(k<=XList.length-1)
  {
    if(index_selected_answer == index_right_answer[k] && yourTotal<XList.length)
	  { //is correct
        cross[k].setAttribute({visible:false});
        exclaim[k].setAttribute({visible:false});
        check[k].setAttribute({visible:true});
        yourScore = yourScore+1;
      }
      else if(index_selected_answer != index_right_answer[k] && yourTotal<XList.length && index_selected_answer != 0)
      { //is incorrect
        exclaim[k].setAttribute({visible:false});
        check[k].setAttribute({visible:false});
        cross[k].setAttribute({visible:true});
		    yourWrong = yourWrong+1;
      }
	  else if (index_selected_answer == 0 && yourTotal<XList.length)
	  {   check[k].setAttribute({visible:false});
        cross[k].setAttribute({visible:false});
        exclaim[k].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(k==XList.length-1)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  question.setAttribute({visible:false});
  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    k=k+1
  }
  hide[k].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ini();
  index_selected_answer = -1;
});
/************************************************/
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
	  k=0; ini();
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    ////////////////////////ALL CHANGES GO HERE/////////////
    //Ptm.moveTo([XList[0], YList[0]], 200);
    ////////////////////////////////////////////////////////
    //alpha.setAttribute({visible:true});
    //beta.setAttribute({visible:true});
});
/********************PLACE LOGO ********************/
var km=0;
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
edliy.on('down',
function(){
  km = toggle(km);
	vis = toggleTF(vis);
  bck.setAttribute({opacity:km});
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
  hint.setAttribute({visible:vis});
	question.setAttribute({visible:!vis});
  note.setAttribute({visible:false});
});
graph.unsuspendUpdate();
/******************END OF FILE ***************************/
}
}
export default Boxes;
